import 'knockout.validation';
import * as ko from 'knockout';

import { IAddPetRequest, IPetDto, IStatus } from '@/Common/interfaces';
import { Texts } from '@/Common/Texts';
import Modal from '../Common/Modal';
import { AlertViewModel } from './AlertViewModel';
import { fetch } from '@/Common/fetch';
import { PetSexEnum, PetTypeEnum } from '@/Common/enums';

export class PetModalViewModel {
    public selectedPetId: KnockoutObservable<string>;
    public selectedPetToDelete: IPetDto;
    public inputGroup: KnockoutValidationGroup;

    public loading: KnockoutObservable<boolean>;

    public name: KnockoutObservable<string>;
    public type: KnockoutObservable<PetTypeEnum>;
    public sex: KnockoutObservable<PetSexEnum>;
    public weight: KnockoutObservable<string>;
    public race: KnockoutObservable<string>;
    public birthMonth: KnockoutObservable<number>;
    public birthYear: KnockoutObservable<number>;
    public birthDate: KnockoutObservable<Date>;

    public petTypeOptions: KnockoutObservableArray<{
        value: PetTypeEnum;
        text: string;
    }>;
    public petSexOptions: KnockoutObservableArray<{
        value: PetSexEnum;
        text: string;
    }>;
    public months: KnockoutObservableArray<{ value: number; text: string }>;
    public years: KnockoutObservableArray<{ value: number; text: string }>;

    public alertModal: AlertViewModel;

    constructor() {
        const self = this;
        self.alertModal = new AlertViewModel();

        self.loading = ko.observable(false);

        self.name = ko.observable('').extend({
            required: {
                param: true,
                message: Texts.getResource('PleaseEnterPetName'),
            },
        });
        self.type = ko.observable(null).extend({
            required: {
                param: true,
                message: Texts.getResource('PleaseSelectPetType'),
            },
        });
        self.sex = ko.observable(null).extend({
            required: {
                param: true,
                message: Texts.getResource('PleaseSelectPetSex'),
            },
        });
        self.race = ko.observable('');
        self.weight = ko.observable('').extend({
            isNumberOrEmpty: {
                param: true,
                message: Texts.getResource('PleaseEnterANumber'),
            },
        });

        self.birthMonth = ko.observable(null).extend({
            required: {
                param: true,
                message: Texts.getResource('PleaseSelectMonth'),
            },
        });

        self.birthYear = ko.observable(null).extend({
            required: {
                param: true,
                message: Texts.getResource('PleaseSelectYear'),
            },
        });

        self.birthDate = ko
            .computed(() => {
                return new Date(this.birthYear(), this.birthMonth(), 2);
            })
            .extend({
                petBirthdateNotInTheFuture: {
                    param: true,
                    message: Texts.getResource('ErrorDateInTheFuture'),
                },
            });

        self.inputGroup = ko.validatedObservable([
            self.name,
            self.race,
            self.birthDate,
            self.type,
            self.sex,
            self.birthMonth,
            self.birthYear,
        ]);
        self.inputGroup.errors.showAllMessages(false);

        self.selectedPetId = ko.observable(null);

        self.petTypeOptions = ko.observableArray([
            { value: null, text: Texts.getResource('SelectPetType') },
            { value: PetTypeEnum.Dog, text: Texts.getResource('PetTypeDog') },
            { value: PetTypeEnum.Cat, text: Texts.getResource('PetTypeCat') },
            {
                value: PetTypeEnum.Rabbit,
                text: Texts.getResource('PetTypeRabbit'),
            },
            {
                value: PetTypeEnum.Rodent,
                text: Texts.getResource('PetTypeRodent'),
            },
        ]);

        self.petSexOptions = ko.observableArray([
            { value: null, text: Texts.getResource('SelectPetSex') },
            {
                value: PetSexEnum.Female,
                text: Texts.getResource('PetSexFemale'),
            },
            {
                value: PetSexEnum.FemaleSterilized,
                text: Texts.getResource('PetSexFemaleSterilized'),
            },
            { value: PetSexEnum.Male, text: Texts.getResource('PetSexMale') },
            {
                value: PetSexEnum.MaleNeutered,
                text: Texts.getResource('PetSexMaleNeutered'),
            },
            {
                value: PetSexEnum.Unknown,
                text: Texts.getResource('PetSexUnknown'),
            },
        ]);

        self.months = ko.observableArray([
            { value: null, text: Texts.getResource('SelectMonth') },
            { value: 0, text: Texts.getResource('MonthJanuary') },
            { value: 1, text: Texts.getResource('MonthFebruary') },
            { value: 2, text: Texts.getResource('MonthMarch') },
            { value: 3, text: Texts.getResource('MonthApril') },
            { value: 4, text: Texts.getResource('MonthMay') },
            { value: 5, text: Texts.getResource('MonthJune') },
            { value: 6, text: Texts.getResource('MonthJuly') },
            { value: 7, text: Texts.getResource('MonthAugust') },
            { value: 8, text: Texts.getResource('MonthSeptember') },
            { value: 9, text: Texts.getResource('MonthOctober') },
            { value: 10, text: Texts.getResource('MonthNovember') },
            { value: 11, text: Texts.getResource('MonthDecember') },
        ]);

        const currentYear = new Date().getFullYear();
        const maxYears = currentYear - 30;

        self.years = ko.observableArray([
            { value: null, text: Texts.getResource('SelectYear') },
            ...Array.from(
                { length: currentYear - maxYears + 1 },
                (_, i) => maxYears + i,
            ).map((year) => ({ value: year, text: year.toString() })),
        ]);
    }

    public openAddPetModal = () => {
        this.selectedPetId(null);
        this.name('');
        this.race('');
        this.type(null);
        this.sex(null);
        this.weight('');
        this.birthMonth(null);
        this.birthYear(null);
        this.setModalTitle();
        this.inputGroup.errors.showAllMessages(false);
        Modal.show('petModal');
    };

    public openEditPetModal = (pet: IPetDto) => {
        this.name(pet.Name);
        this.type(pet.Type);
        this.sex(pet.Sex);
        this.weight(pet.WeightKg?.toString()?.replace('.', ','));
        this.race(pet.Race);
        this.birthMonth(new Date(pet.Birthdate).getMonth());
        this.birthYear(new Date(pet.Birthdate).getFullYear());
        this.selectedPetId(pet.Id);
        this.setModalTitle();
        this.inputGroup.errors.showAllMessages(false);
        Modal.show('petModal');
    };

    public addPet = async () => {
        if (!this.inputGroup.isValid()) {
            this.inputGroup.errors.showAllMessages(true);
            return;
        }

        if (this.loading()) return;
        this.loading(true);

        const request: IAddPetRequest = {
            Name: this.name(),
            Type: this.type(),
            Race: this.race(),
            Sex: this.sex(),
            Birthdate: this.birthDate(),
            WeightKg: this.parseNumber(this.weight()),
        };

        try {
            const response: IStatus<string> = await fetch(
                '/api/pets',
                'POST',
                request,
            );

            if (response.HasErrors) {
                this.alertModal.openAlert(
                    Texts.getResource('CouldNotCreatePet') +
                        response.Errors.map((e) => e.Value).join('\n'),
                );
                Modal.close('petModal');
                return;
            }

            window.location.reload();
        } catch (error) {
            this.alertModal.openAlert(
                Texts.getResource('CouldNotCreatePet') + error,
            );
            Modal.close('petModal');
        }
    };

    public editPet = async () => {
        if (!this.inputGroup.isValid()) {
            this.inputGroup.errors.showAllMessages(true);
            return;
        }

        if (this.loading()) return;
        this.loading(true);

        const petToUpdate: IPetDto = {
            Id: this.selectedPetId(),
            Name: this.name(),
            Type: this.type(),
            Race: this.race(),
            Sex: this.sex(),
            Birthdate: this.birthDate(),
            WeightKg: this.parseNumber(this.weight()),
        };

        try {
            const response: IStatus<string> = await fetch(
                '/api/pets/',
                'PUT',
                petToUpdate,
            );

            if (response.HasErrors) {
                this.alertModal.openAlert(
                    Texts.getResource('CouldNotUpdatePet') +
                        response.Errors.map((e) => e.Value).join('\n'),
                );
                Modal.close('petModal');
                return;
            }

            window.location.reload();
        } catch (error) {
            this.alertModal.openAlert(
                Texts.getResource('CouldNotUpdatePet') + error,
            );
            Modal.close('petModal');
        }
    };

    public openDeletePetModal(petToDelete: IPetDto): void {
        this.selectedPetToDelete = petToDelete;
        Modal.show('confirm-modal-pet');
    }

    public closeDeletePetModal(): void {
        this.selectedPetToDelete = null;
        Modal.close('confirm-modal-pet');
    }

    public deletePet = async () => {
        try {
            const response: IStatus<string> = await fetch(
                `/api/pets/${this.selectedPetToDelete.Id}`,
                'DELETE',
            );

            if (response.HasErrors) {
                this.alertModal.openAlert(
                    Texts.getResource('ErrorDeletePet') +
                        response.Errors.map((e) => e.Value).join('\n'),
                );
                Modal.close('confirm-modal-pet');
                return;
            }
            window.location.reload();
        } catch (error) {
            this.alertModal.openAlert(
                Texts.getResource('ErrorDeletePet') + error,
            );
            Modal.close('confirm-modal-pet');
        }
    };

    public setModalTitle() {
        const titleElement = document.getElementById('petModal-title');
        if (titleElement) {
            if (this.selectedPetId() == null) {
                titleElement.textContent = Texts.getResource('Profile_AddPet');
            } else {
                titleElement.textContent = Texts.getResource('Profile_EditPet');
            }
        }
    }

    public getPetIconPath(petType: PetTypeEnum): string {
        switch (petType) {
            case PetTypeEnum.Dog:
                return '/images/icons/pet-dog.svg';
            case PetTypeEnum.Cat:
                return '/images/icons/pet-cat.svg';
            case PetTypeEnum.Rabbit:
                return '/images/icons/pet-rabbit.svg';
            case PetTypeEnum.Rodent:
                return '/images/icons/pet-rodent.svg';
            default:
                return '/images/icons/pet-dog.svg';
        }
    }

    public parseNumber(input: string): number | null {
        if (!input) return null;

        const standardizedInput = input.replace(',', '.');
        const parsedValue = parseFloat(standardizedInput);

        if (isNaN(parsedValue)) {
            throw new Error('Invalid number format');
        }

        return parsedValue;
    }
}
