import 'knockout.validation';
import * as ko from 'knockout';

import {
    IAdultHouseholdMember,
    IAdultHouseholdMemberDto,
    IKid,
    IPatientDto,
    IPet,
    IPetDto,
    ISelfResponse,
    IStatus,
    IUpdateSelfRequest,
} from '@/Common/interfaces';
import { Log } from '@/Common/Log';
import { Texts } from '@/Common/Texts';
import { Utils } from '@/Common/Utils';
import { AlertViewModel } from './AlertViewModel';
import { ReceiveVideoCall } from './consultations/ReceiveVideoCallFromDoctor';
import Modal from '../Common/Modal';
import { ChildModalViewModel } from './ChildModalViewModel';
import { PartnerModalViewModel } from './PartnerModalViewModel';
import { PetModalViewModel } from '@/ViewModels/PetModalViewModel';
import { fetch } from '@/Common/fetch';

export class ProfileViewModel {
    public receiveVideoCall: ReceiveVideoCall;
    public childModal: ChildModalViewModel;
    public partnerModal: PartnerModalViewModel;
    public petModal: PetModalViewModel;

    public profileState: KnockoutObservable<string>;
    public isSubmitting: KnockoutComputed<boolean>;
    public profileErrorMessage: KnockoutObservable<string>;

    public isSubscriptionOwner: KnockoutObservable<boolean>;

    // My information
    public name: KnockoutObservable<string>;
    public CPR: KnockoutObservable<string>;
    public cprFormatted: KnockoutObservable<string>;
    public email: KnockoutObservable<string>;
    public phone: KnockoutObservable<string>;
    public emailEdit: KnockoutObservable<string>;
    public phoneEdit: KnockoutObservable<string>;

    public policyNumber: KnockoutObservable<string>;
    public hasPolicyNumber: KnockoutComputed<boolean>;
    public kids: KnockoutObservableArray<IKid>;
    public adultHouseholdMembers: KnockoutObservableArray<IAdultHouseholdMember>;
    public pets: KnockoutObservableArray<IPet>;

    public error: KnockoutValidationGroup;
    public profileError: KnockoutValidationGroup;

    public newsletterSwitch = ko.observable(false);
    public newsletterSwitchDisabled = ko.observable(false);

    private profileStates = {
        ready: 'ready',
        saving: 'saving',
        saved: 'saved',
    };

    private alertModal: AlertViewModel;

    constructor() {
        const self = this;
        self.alertModal = new AlertViewModel();
        self.receiveVideoCall = new ReceiveVideoCall();
        self.childModal = new ChildModalViewModel();
        self.partnerModal = new PartnerModalViewModel();
        self.petModal = new PetModalViewModel();

        self.profileState = ko.observable(self.profileStates.ready);
        self.isSubmitting = ko.computed(() => {
            return self.profileState() == self.profileStates.saving;
        });
        self.profileErrorMessage = ko.observable(null);

        self.isSubscriptionOwner = ko.observable(false);
        self.name = ko.observable('').extend({
            required: {
                param: true,
                message: Texts.getResource('NameRequired'),
            },
            fullname: true,
        });
        self.CPR = ko
            .observable('')
            .extend({ cprClean: true })
            .extend({
                required: {
                    param: true,
                    message: Texts.getResource('CprRequired'),
                },
                pattern: {
                    message: Texts.getResource('InvalidCpr'),
                    params: '^[0-9]{10}$',
                },
            });
        self.cprFormatted = ko.pureComputed(function () {
            return Utils.formatCpr(self.CPR());
        });
        self.email = ko.observable('').extend({
            required: {
                param: true,
                message: Texts.getResource('EmailRequired'),
            },
            maxLength: 256,
            email: {
                message: Texts.getResource('InvalidEmail'),
            },
        });
        self.emailEdit = ko.observable('').extend({
            required: {
                param: true,
                message: Texts.getResource('EmailRequired'),
            },
            maxLength: 256,
            email: {
                message: Texts.getResource('InvalidEmail'),
            },
        });
        self.phone = ko.observable(null).extend({
            pattern: {
                params: '^[+]?[0-9]{8,20}$',
                message: Texts.getResource('InvalidPhoneNumber'),
            },
            validation: {
                validator: function (val: string) {
                    return val !== null && val.trim() !== '';
                },
                message: Texts.getResource('PhoneNumberRequired'),
            },
        });
        self.phoneEdit = ko.observable(null).extend({
            pattern: {
                params: '^[+]?[0-9]{8,20}$',
                message: Texts.getResource('InvalidPhoneNumber'),
            },
            validation: {
                validator: function (val: string) {
                    return val !== null && val.trim() !== '';
                },
                message: Texts.getResource('PhoneNumberRequired'),
            },
        });
        self.policyNumber = ko.observable('');

        self.hasPolicyNumber = ko.pureComputed(() => {
            return self.policyNumber() && self.policyNumber().length > 0;
        });

        self.profileError = ko.validatedObservable([
            self.phoneEdit,
            self.emailEdit,
        ]);
        self.profileError.errors.showAllMessages(false);

        self.kids = ko.observableArray([]);

        self.adultHouseholdMembers = ko.observableArray([]);

        self.pets = ko.observableArray([]);

        self.error = ko.validatedObservable([
            self.name,
            self.CPR,
            self.email,
            self.phone,
        ]);
        self.error.errors.showAllMessages(false);
    }

    public getProfileData = async (onlyPartnerData = false) => {
        const self = this;

        await fetch('/api/patients/self', 'GET')
            .then((res: ISelfResponse) => {
                self.getProfileDataCallback(res, onlyPartnerData);
            })
            .catch((err) => {
                self.alertModal.openAlert(
                    Texts.getResource('ErrorFetchingProfileData') + '\n' + err,
                );
            });

        await this.getPets();
    };

    public getProfileDataCallback(
        response: ISelfResponse,
        onlyPartnerData: boolean,
    ) {
        const self = this;
        if (!onlyPartnerData) {
            if (!response.Me || !response.Me.User) {
                Log.error('getProfileDataCallback: User not set', response);
                self.alertModal.openAlert(
                    Texts.getResource('ErrorFetchingProfileData'),
                );
                return;
            }
            self.name(response.Me.User.FullName);
            self.CPR(response.Me.CPR);
            self.email(response.Me.Email);
            self.phone(response.Me.MobilePhone);
            self.policyNumber(response.Me.PolicyNumber);
            self.isSubscriptionOwner(response.Me.IsSubscriptionOwner);
            self.newsletterSwitch(response.Me.AcceptMarketing);
            self.newsletterSwitch.subscribe((newValue) =>
                this.toggleNewsletterSwitch(newValue),
            );

            self.kids.removeAll();
            response.Kids.forEach((item: IPatientDto) => {
                const currentKid: IKid = {
                    Name: item.User.FullName,
                    UID: item.User.UID,
                    CPR: item.CPR,
                    FormattedCpr: Utils.formatCpr(item.CPR),
                };
                self.kids.push(currentKid);
            });

            self.profileError.errors.showAllMessages(false);
        }

        if (response.AdultHouseholdMembers !== null) {
            self.adultHouseholdMembers([]);
            response.AdultHouseholdMembers.forEach(
                (item: IAdultHouseholdMemberDto) => {
                    const currentAdultHouseholdMember: IAdultHouseholdMember = {
                        ID: item.ID,
                        UID: item.UID,
                        Name: item.Name,
                        Email: item.Email,
                        Status: item.Status,
                        Cpr: item.Cpr,
                    };
                    self.adultHouseholdMembers.push(
                        currentAdultHouseholdMember,
                    );
                },
            );
        }
    }

    public getPets = async () => {
        try {
            const response: IPetDto[] = await fetch('/api/pets', 'GET');
            response.forEach((x: IPetDto) => {
                const pet: IPet = {
                    ...x,
                    IconPath: this.petModal.getPetIconPath(x.Type),
                };
                this.pets.push(pet);
            });
        } catch (error) {
            this.alertModal.openAlert(
                Texts.getResource('CouldNotGetPets') + error,
            );
            Modal.close('petModal');
        }
    };

    public saveProfileData(): void {
        const self = this;

        if (!self.profileError.isValid()) {
            self.profileState(self.profileStates.ready);
            self.profileError.errors.showAllMessages();
            self.profileErrorMessage(Texts.getResource('FormInvalidValue'));

            return;
        }

        self.profileState(self.profileStates.saving);
        self.profileErrorMessage('');

        const request: IUpdateSelfRequest = {
            Name: self.name(),
            Email: self.emailEdit(),
            CPR: self.CPR(),
            Mobile: self.phoneEdit(),
        };

        const callBackAjax = (
            data: IStatus<ISelfResponse>,
            status: string,
            jqXhr: JQueryXHR,
        ): void => {
            if (data.HasErrors) {
                const error = data.Errors.map((error) => error.Value).join(
                    '\n',
                );
                self.profileErrorMessage(error);
                self.profileState(self.profileStates.ready);
                return;
            }

            this.email(this.emailEdit());
            this.phone(this.phoneEdit());

            self.profileState(self.profileStates.saved);
            self.getProfileDataCallback(data.Result, false);
            Modal.close('editMyInfoModal');
        };

        const errorCallBack = (
            jqXhr: JQueryXHR,
            textStatus: string,
            error: string,
        ): void => {
            const json = JSON.parse(jqXhr.responseText);
            self.profileErrorMessage(json.Message || json.Title);
            self.profileState(self.profileStates.ready);
            Modal.close('editMyInfoModal');
        };

        Utils.ajaxCall(
            'POST',
            'api/patients/self/v2',
            callBackAjax,
            errorCallBack,
            JSON.stringify(request),
        );
    }

    public editMyInfo = () => {
        this.profileErrorMessage('');
        this.phoneEdit(this.phone());
        this.emailEdit(this.email());
        const self = this;
        self.profileState(self.profileStates.ready);
        Modal.show('editMyInfoModal');
    };

    public deleteAccount(): void {
        const callBackAjax = (
            data: any,
            textStatus: string,
            jqXhr: JQueryXHR,
        ): void => {
            this.closeDeleteAccountModal();
            this.logout();
        };
        const errorCallBack = (
            jqXhr: JQueryXHR,
            textStatus: string,
            error: string,
        ): void => {
            this.closeDeleteAccountModal();
            this.alertModal.openAlert(Texts.getResource('ErrorDeleteAccount'));
        };

        Utils.ajaxCall(
            'DELETE',
            'api/patients/account',
            callBackAjax,
            errorCallBack,
            null,
            null,
            false,
        );
    }

    public openDeleteAccountModal(): void {
        Modal.show('delete-account-modal');
    }

    public closeDeleteAccountModal(): void {
        Modal.close('delete-account-modal');
    }

    public closeConfirmModal(): void {
        Modal.close('confirm-modal');
    }

    public openRemoveInsurancePartnerModal(): void {
        Modal.show('remove-insurance-partner-modal');
    }

    public closeRemoveInsurancePartnerModal(): void {
        Modal.close('remove-insurance-partner-modal');
    }

    public openUnsubscribeModal(): void {
        Modal.show('confirm-unsubscribe-modal');
    }

    public closeUnsubscribeModal(): void {
        Modal.close('confirm-unsubscribe-modal');
    }

    public logout(): boolean {
        Utils.logout();
        return true;
    }

    public async toggleNewsletterSwitch(newValue: boolean) {
        const self = this;
        self.newsletterSwitchDisabled(true);

        const url = newValue
            ? `/email/subscribe?email=${encodeURIComponent(self.email())}&getView=false`
            : `/email/unsubscribe?email=${encodeURIComponent(self.email())}&getView=false`;

        await fetch(url, 'GET')
            .then((_: ISelfResponse) => {
                self.newsletterSwitchDisabled(false);
                self.newsletterSwitch(newValue);
            })
            .catch((err) => {
                self.newsletterSwitchDisabled(false);
                self.alertModal.openAlert(
                    Texts.getResource('ErrorTogglingNewsletter') + '\n' + err,
                );
            });
    }

    public chooseLanguageOnEnter(
        data: ProfileViewModel,
        event: JQuery.Event,
        language: 'dansk' | 'english',
    ) {
        if (event.key !== 'Enter') return true; // to avoid prevention of default behaviour

        window.location.href = window.location.pathname + '?lang=' + language;
    }

    public toggleNewsletterOnEnter(
        data: ProfileViewModel,
        event: JQuery.Event,
    ) {
        if (event.key !== 'Enter') return true; // to avoid prevention of default behaviour

        this.toggleNewsletterSwitch(!this.newsletterSwitch()).then(null);
    }
}
